import { Cookies } from 'react-cookie';

/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'login' */ './login'),
      async action({ next }) {
        const route = await next();
        if (route) {
          route.admin_route = true;
          route.title = `${route.title || 'Best Day Ever'}`;
          route.description = route.description || '';
        }
        return route;
      },
      /* action() {
          return { redirect: '/admin/login' } // <== request a redirect
        }, */
    },
    {
      path: '/signup',
      load: () => import(/* webpackChunkName: 'signup' */ './signup'),
    },
    {
      path: '/admin',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'login' */ './login'),
        },
        {
          path: '/login',
          load: () => import(/* webpackChunkName: 'login' */ './login'),
        },
        {
          path: '/forgot-password',
          load: () =>
            import(
              /* webpackChunkName: 'forgot-password' */ './forgot-password'
            ),
        },
        {
          path: '/reset-password/:token',
          load: () =>
            import(/* webpackChunkName: 'reset-password' */ './reset-password'),
        },
        {
          path: '/',
          children: [
            {
              path: '/dashboard',
              load: () => import(/* webpackChunkName: 'event' */ './dashboard'),
            },
            {
              path: '/my-profile',
              load: () =>
                import(/* webpackChunkName: 'event' */ './my-profile'),
            },
            {
              path: '/event',
              load: () =>
                import(/* webpackChunkName: 'event' */ './event/list'),
            },
            {
              path: '/event/add',
              load: () => import(/* webpackChunkName: 'event' */ './event/add'),
            },
            {
              path: '/event/edit/:id',
              load: () =>
                import(/* webpackChunkName: 'event' */ './event/edit'),
              name: 'edit_event',
            },
            {
              path: '/event/manage/:id',
              load: () =>
                import(/* webpackChunkName: 'event' */ './event/view-event'),
              name: 'view_event',
            },
            {
              path: '/event/manage-options/:id',
              load: () =>
                import(/* webpackChunkName: 'event' */ './event/manage-option'),
              name: 'manage-option',
            },
            {
              path: '/event/manage-bingo/:event_token/:id',
              load: () =>
                import(/* webpackChunkName: 'event' */ './event/manage-bingo'),
              name: 'manage-bingo',
            },
            {
              path: '/event/manage-photos/:id',
              load: () =>
                import(/* webpackChunkName: 'event' */ './event/manage-photos'),
              name: 'manage-photos',
            },
            {
              path: '/event/manage-squares/:id',
              load: () =>
                import(
                  /* webpackChunkName: 'event' */ './event/manage-squares'
                ),
              name: 'event_manage_squares',
            },
            {
              path: '/event/manage-questions/:id',
              load: () =>
                import(
                  /* webpackChunkName: 'event' */ './event/manage-questions'
                ),
              name: 'event_manage_questions',
            },
            {
              path: '/event/declare-winner/:id',
              load: () =>
                import(
                  /* webpackChunkName: 'event' */ './event/declare-winner'
                ),
              name: 'event_declare_winner',
            },
            {
              path: '/email',
              load: () => import(/* webpackChunkName: 'game' */ './email/add'),
            },
            {
              path: '/game',
              load: () => import(/* webpackChunkName: 'game' */ './game/list'),
            },
            {
              path: '/game/add',
              load: () => import(/* webpackChunkName: 'game' */ './game/add'),
            },
            {
              path: '/game/edit/:id',
              load: () => import(/* webpackChunkName: 'game' */ './game/edit'),
            },
            {
              path: '/event/:eventId/guest',
              load: () => import(/* webpackChunkName: 'game' */ './guest/list'),
            },
            {
              path: '/event/:eventId/guest/add',
              load: () => import(/* webpackChunkName: 'game' */ './guest/add'),
            },
            {
              path: '/event/:eventId/guest/upload',
              load: () =>
                import(/* webpackChunkName: 'game' */ './guest/upload'),
            },
            {
              path: '/event/:eventId/guest/edit/:id',
              load: () => import(/* webpackChunkName: 'game' */ './guest/edit'),
            },
            {
              path: '/event/:eventId/guest/update/:id',
              load: () =>
                import(/* webpackChunkName: 'game' */ './guest/update'),
            },
            {
              path: '/event/:eventId/guest/more/:id',
              load: () => import(/* webpackChunkName: 'game' */ './guest/more'),
            },
            {
              path: '/client',
              load: () =>
                import(/* webpackChunkName: 'game' */ './client/list'),
            },
            {
              path: '/client/add',
              load: () => import(/* webpackChunkName: 'game' */ './client/add'),
            },
            {
              path: '/client/edit/:id',
              load: () =>
                import(/* webpackChunkName: 'game' */ './client/edit'),
            },
            {
              path: '/client/view/:id',
              load: () =>
                import(/* webpackChunkName: 'game' */ './client/view-client'),
              name: 'view-client',
            },
            {
              path: '/team',
              load: () => import(/* webpackChunkName: 'team' */ './team/list'),
            },
            {
              path: '/team/add',
              load: () => import(/* webpackChunkName: 'team' */ './team/add'),
            },
            {
              path: '/team/edit/:id',
              load: () => import(/* webpackChunkName: 'team' */ './team/edit'),
            },
            {
              path: '/player/add',
              load: () => import(/* webpackChunkName: 'team' */ './player/add'),
            },
            {
              path: '/player/upload',
              load: () =>
                import(/* webpackChunkName: 'team' */ './player/upload'),
            },
            {
              path: '/options/add',
              load: () =>
                import(/* webpackChunkName: 'team' */ './options/add'),
            },
            {
              path: '/options/upload',
              load: () =>
                import(/* webpackChunkName: 'team' */ './options/upload'),
            },
            {
              path: '/suite',
              load: () => import(/* webpackChunkName: 'team' */ './suite/list'),
            },
            {
              path: '/suite/add',
              load: () => import(/* webpackChunkName: 'team' */ './suite/add'),
            },
            {
              path: '/suite/edit/:id',
              load: () => import(/* webpackChunkName: 'team' */ './suite/edit'),
            },
            {
              path: '/user',
              load: () => import(/* webpackChunkName: 'team' */ './user/list'),
            },
            {
              path: '/user/add',
              load: () => import(/* webpackChunkName: 'team' */ './user/add'),
            },
            {
              path: '/user/edit/:id',
              load: () => import(/* webpackChunkName: 'team' */ './user/edit'),
            },
            {
              path: '/stadium',
              load: () =>
                import(/* webpackChunkName: 'team' */ './stadium/list'),
            },
            {
              path: '/stadium/add',
              load: () =>
                import(/* webpackChunkName: 'team' */ './stadium/add'),
            },
            {
              path: '/stadium/edit/:id',
              load: () =>
                import(/* webpackChunkName: 'team' */ './stadium/edit'),
            },

            {
              path: '/reports',
              load: () =>
                import(/* webpackChunkName: 'team' */ './report/list'),
            },
            {
              path: '/report/add',
              load: () => import(/* webpackChunkName: 'team' */ './report/add'),
            },
            {
              path: '/report/view/:id',
              load: () =>
                import(/* webpackChunkName: 'team' */ './report/view-report'),
            },
            {
              path: '/report/edit/:id',
              load: () =>
                import(/* webpackChunkName: 'team' */ './report/edit'),
            },
            {
              path: '/icon/add',
              load: () =>
                import(/* webpackChunkName: 'icon' */ './icon/add'),
            },
          ],
          async action({ next }) {
            const route = await next();
            if (route) {
              route.admin_auth = true;
              route.admin_route = true;
              route.title = `${route !== undefined ? route.title : 'Best Day Ever'
                }`;
              route.description = route.description || '';
            }
            return route;
          },
        },
        {
          path: '(.*)',
          load: () => import(/* webpackChunkName: 'login' */ './not-found'),
        },
      ],
      async action({ next }) {
        const route = await next();
        if (route) {
          route.admin_route = true;
          route.title = `${route.title || 'Best Day Ever'}`;
          route.description = route.description || '';
        }

        return route;
      },
    },

    {
      path: '/test',
      load: () => import(/* webpackChunkName: 'test' */ './test'),
    },
    {
      path: '/zurich',
      children: [

        {
          path: "/dashboard/:event_token/:access_token",
          load: () => import(/* webpackChunkName: 'zurich-game' */ './front/zurich-game/dashboard'),
        },
        {
          path: "/classictrivia/:event_token?/:access_token?",
          load: () => import(/* webpackChunkName: 'zurich-game' */ './front/zurich-game/classictrivia'),
        },
        {
          path: "/triviaconfirmation/:event_token?/:access_token?",
          load: () => import(/* webpackChunkName: 'zurich-game' */ './front/zurich-game/triviaconfirmation'),
        },
        {
          path: "/socialshare/:event_token?/:access_token?",
          load: () => import(/* webpackChunkName: 'zurich-game' */ './front/zurich-game/socialshare'),
        },
        {
          path: "/pickteams/:event_token?/:access_token?",
          load: () => import(/* webpackChunkName: 'zurich-game' */ './front/zurich-game/pickteams'),
        },
        {
          path: "/draftconfirmation/:event_token?/:access_token?",
          load: () => import(/* webpackChunkName: 'zurich-game' */ './front/zurich-game/draftconfirmation'),
        },
        {
          path: "/weekdayprediction/:event_token?/:access_token?",
          load: () => import(/* webpackChunkName: 'zurich-game' */ './front/zurich-game/weekdayprediction'),
        },
        {
          path: "/leaderboard/:event_token?/:access_token?",
          load: () => import(/* webpackChunkName: 'zurich-game' */ './front/zurich-game/leaderboard'),
        },
        {
          path: "/:event_token/:access_token?",
          load: () => import(/* webpackChunkName: 'zurich-game' */ './front/zurich-game/signup'),
        },
        // {
        //   path: "",
        //   load: () => import(/* webpackChunkName: 'zurich-game' */ './front/zurich-game/signup'),
        // },
      ],
      async action({ next }) {
        const route = await next();
        if (route) {
          route.zurich_route = true;
          // route.title = `${route.title || 'Best Day Ever'}`;
          // route.description = route.description || '';
        }

        return route;
      },
    },
    {
      path: '/:event_token',
      load: () => import(/* webpackChunkName: 'front' */ './front/step1'),
    },
    {
      path: '/wmpo/leaderboard/:leaderboard_token',
      load: () =>
        import(
          /* webpackChunkName: 'wmpo/leaderboard' */ './front/wmpo/wmpoLeaderboard'
        ),
    },
    {
      path: '/wmpo/dashboard/:event_token/:game_id/:guest_token',
      load: () =>
        import(
          /* webpackChunkName: 'wmpo/dashboard' */ './front/wmpo/wmpoDashboard'
        ),
    },
    {
      path: '/wmpo/:event_token/:guest_token',
      load: () =>
        import(
          /* webpackChunkName: 'wmpo/draft' */ './front/wmpo/wmpoDraftLanding'
        ),
    },
    /* Add my own /draft page here */
    {
      path: '/wmpo/draft/:event_token/:game_id/:guest_token',
      load: () =>
        import(/* webpackChunkName: 'wmpo/draft' */ './front/wmpo/wmpoDraft'),
    },
    {
      path: '/wmpo/:event_token',
      load: () => import(/* webpackChunkName: 'wmpo' */ './front/wmpo'),
    },
    {
      path: '/zurich/:event_token/:guest_token',
      load: () => import(/* webpackChunkName: 'wmpo' */ './front/zurich'),
    },
    {
      path: '/:event_token/:guest_token/:game_id/draft',
      load: () =>
        import(/* webpackChunkName: 'front' */ './front/fantasy/draft'),
    },
    {
      path: '/:event_token/:guest_token/:game_id/fish',
      load: () =>
        import(/* webpackDhunkName: 'front' */ './front/fish'),
    },
    {
      path: '/:event_token/:guest_token/thank-you',
      load: () =>
        import(
          /* webpackChunkName: 'front' */ './front/fantasy/fantasyThankyou'
        ),
    },
    {
      path: '/:event_token/:guest_token/:game_id/square-game',
      load: () => import(/* webpackChunkName: 'front' */ './front/squares'),
    },
    {
      path: '/:event_token/:guest_token/:game_id/thank-you',
      load: () => import(/* webpackChunkName: 'front' */ './front/thankyou'),
    },
    {
      path: '/:event_token/:guest_token/:game_id/bingo',
      load: () =>
        import(/* webpackChunkName: 'front' */ './front/bingo/bingoBoard'),
    },
    {
      path: '/:event_token/:guest_token/:game_id/21-questions',
      load: () => import(/* webpackChunkName: 'front' */ './front/game2'),
    },
    {
      path: '/:event_token/:guest_token/:game_id/thank-you',
      load: () => import(/* webpackChunkName: 'front' */ './front/thankyou'),
    },
    {
      path: '/:event_token/:guest_token/:game_id/lone-wolf',
      load: () => import(/* webpackChunkName: 'front' */ './front/lonewolf'),
    },
    {
      path: '/:event_token/:guest_token/lonewolf-thank-you',
      load: () =>
        import(/* webpackChunkName: 'front' */ './front/lonewolfthankyou'),
    },
    {
      path: '/fish/leaderboard/:event_token',
      load: () =>
        import(/* webpackChunkName: 'front' */ './front/fishLeaderboard'),
    },
    {
      path: '/wmpo/smallbusiness/leaderboard/:event_token',
      load: () =>
        import(/* webpackChunkName: 'front' */ './front/fantasy/dashboard'),
    },
    {
      path: '/draft-leaderboard/:leaderboard_token',
      load: () =>
        import(/* webpackChunkName: 'front' */ './front/generalLeaderboard'),
    },

    {
      path: '/leaderboard/:leaderboard_token',
      load: () => import(/* webpackChunkName: 'front' */ './front/leaderboard'),
    },
    {
      path: '/21question-leaderboard/:leaderboard_token',
      load: () =>
        import(/* webpackChunkName: 'front' */ './front/generalLeaderboard'),
    },
    {
      path: '/golfleaderboard/:leaderboard_token',
      load: () =>
        import(/* webpackChunkName: 'front' */ './front/golfleaderboard'),
    },
    {
      path: '/golfleaderboard/:event_token/:guest_token',
      load: () =>
        import(/* webpackChunkName: 'front' */ './front/lonewolfPersonalBoard'),
    },
    {
      path: '/adminside/golfleaderboard/:leaderboard_token',
      load: () =>
        import(/* webpackChunkName: 'front' */ './front/golfleaderboard'),
    },
    {
      path: '/bingo-leaderboard/:event_token',
      load: () =>
        import(
          /* webpackChunkName: 'front' */ './front/bingo/bingoLeaderboard'
        ),
    },
    {
      path: '/view-report/:id',
      load: () => import(/* webpackChunkName: 'report' */ './report/download'),
    },
    {
      path: '/salesforce-callback/:token',
      load: () =>
        import(
          /* webpackChunkName: 'salesforce-callback' */ './salesforce-callback'
        ),
      async action({ next }) {
        const route = await next();
        if (route) {
          route.title = `${route.title || 'Best Day Ever'}`;
          route.description = route.description || '';
        }
        return route;
      },
    },
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'login' */ './not-found'),
    },
  ],

  async action({ next }) {
    const route = await next();
    if (route) {
      route.title = `${route.title || 'Best Day Ever'}`;
      route.description = route.description || '';
    }
    return route;
  },
};

if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
